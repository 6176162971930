import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Dispatch, SetStateAction, useState } from "react";
import { Button, Divider, Form, Message } from "semantic-ui-react";
import { awsVPCRegions, vpcCloudType } from "../../../../api_generated";
import { useAuth0TokenOptions } from "../../../../data";
import { GET_ENVIRONMENT_QUERY_KEY } from "../../../../query";
import { getErrorMessage } from "../../../../utils/errors";
import { setupVPC } from "../../data";
import { cleanCloudSetupErrorMessage, cloudDetailsTitleStyling } from "../utils";
import AWSDetailsForm from "./AWSDetailsForm";

const AWSSetupView = (props: {
    envShortCode: string;
    envRegion?: awsVPCRegions;
    setShowCloudSpecificSetup: Dispatch<SetStateAction<boolean>>;
}) => {
    // Parent state:
    const { envShortCode, envRegion, setShowCloudSpecificSetup } = props;

    // Auth0 state:
    const auth0TokenOptions = useAuth0TokenOptions();

    // Local state:
    const [roleArn, setRoleArn] = useState<string>("");
    const [errorMsg, setErrorMsg] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(false);

    // Query state:
    const queryClient = useQueryClient();
    const { mutate: mutateSetupVPC } = useMutation({
        mutationFn: () =>
            setupVPC(
                {
                    envShortcode: envShortCode,
                    cloudType: vpcCloudType.AWS,
                    region: envRegion!,
                    roleARN: roleArn,
                },
                auth0TokenOptions,
            ),
        onError: (error) => {
            setErrorMsg(getErrorMessage(error));
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: GET_ENVIRONMENT_QUERY_KEY(envShortCode) });
            setShowCloudSpecificSetup(false);
        },
        onSettled: () => {
            setLoading(false);
        },
    });

    if (envRegion === undefined || !Object.values(awsVPCRegions).includes(envRegion)) {
        return null;
    }

    return (
        <>
            <AWSDetailsForm envShortCode={envShortCode} region={envRegion} />
            <div style={{ textAlign: "left" }}>
                <Form style={{ padding: "24px 0" }}>
                    <label style={{ ...cloudDetailsTitleStyling }}>RoleArn</label>
                    <Form.Input
                        name={"RoleArn"}
                        style={{ width: "100%" }}
                        onChange={(event) => setRoleArn(event.target.value)}
                    />
                </Form>
                <Button
                    loading={loading}
                    disabled={!roleArn}
                    primary
                    style={{ width: "100%" }}
                    fluid
                    size="large"
                    name="method"
                    type="submit"
                    onClick={() => {
                        setLoading(true);
                        mutateSetupVPC();
                    }}
                >
                    Create Predibase Environment
                </Button>
                {errorMsg && (
                    <Message negative>
                        <Message.Header>Error</Message.Header>
                        <div>{cleanCloudSetupErrorMessage(errorMsg)}</div>
                    </Message>
                )}
            </div>
            <Divider hidden />
        </>
    );
};

export default AWSSetupView;
