import { useMutation, useQueryClient } from "@tanstack/react-query";
import { upperFirst } from "lodash";
import { SyntheticEvent, useEffect, useMemo, useState } from "react";
import { useRecoilState } from "recoil";
import { Button, Checkbox, CheckboxProps, Divider, DropdownItemProps, Grid, Header, Icon, Input, Message, Modal, Popup } from "semantic-ui-react";
import {
    acceleratorId,
    baseModel,
    computeReservation,
    createDeploymentRequest,
    deployment,
    deploymentAcceleratorOption,
    deploymentQuantization,
    tier,
    updateDeploymentRequest,
} from "../../../api_generated";
import { DropdownField, IntegerInputField, StringInputField } from "../../../components/modal-utils";
import { useAuth0TokenOptions } from "../../../data";
import { GET_DEPLOYMENTS_QUERY_KEY } from "../../../query";
import { USER_STATE } from "../../../state/global";
import { PREDIBASE_LIGHT_GRAY, SEMANTIC_BLUE, SEMANTIC_GREY } from "../../../utils/colors";
import { getErrorMessage } from "../../../utils/errors";
import { isKratosUserContext } from "../../../utils/kratos";
import { centsToDollars } from "../../../utils/strings";
import { createDeployment, updateDeployment } from "../../data/data";
import { useBaseModelsQuery, useReservationsQuery } from "../../data/query";
import {
    generateAcceleratorSelectorOptions,
    generateBaseModelSelectorOptions,
    isEnterpriseTierModel,
    sortQuantizationTypes,
} from "../../misc/dropdown-utils";

import "./CreateUpdateDeploymentModal.css";
import { useAdaptersListQuery } from "../../../prompt/query";
import { deploymentModelSupportsAdapterModel } from "../../../prompt/utils/model-matching";
import { formatAcceleratorID } from "../../misc/utils";

const formatCooldownTime = (cooldownTime?: number, minReplicas?: number, maxReplicas?: number) => {
    if (cooldownTime === undefined || minReplicas === undefined || maxReplicas === undefined) {
        return <></>;
    }
    // If they are both 0, then we should return nothing
    if (minReplicas === 0 && maxReplicas === 0) {
        return <></>;
    }
    if (minReplicas === maxReplicas) {
        return (
            <>
                Deployment is configured to <b>be always on.</b>
            </>
        );
    }
    if (cooldownTime < 60) {
        return (
            <>
                Scales between{" "}
                <b>
                    {minReplicas} to {maxReplicas}
                </b>{" "}
                replicas. Waits at least <b>{cooldownTime} seconds</b> before scaling down extra replicas.
            </>
        );
    }
    if (cooldownTime < 3600) {
        return (
            <>
                Scales between{" "}
                <b>
                    {minReplicas} to {maxReplicas}
                </b>{" "}
                replicas. Waits at least <b>{Math.round(cooldownTime / 60)} minutes</b> before scaling down extra
                replicas.
            </>
        );
    }
    if (cooldownTime < 86400) {
        return (
            <>
                Scales between{" "}
                <b>
                    {minReplicas} to {maxReplicas}
                </b>{" "}
                replicas. Waits at least <b>{Math.round(cooldownTime / 3600)} hour(s)</b> before scaling down extra
                replicas.
            </>
        );
    }
    return (
        <>
            Scales from{" "}
            <b>
                {minReplicas} to {maxReplicas}
            </b>{" "}
            replicas. Waits at least <b>{Math.round(cooldownTime / 86400)} days</b> before scaling down extra replicas.
        </>
    );
};

const ScalingMessage = (props: {
    autoSuspendSecs: string;
    minReplicas: string;
    maxReplicas: string;
    selectedAccelerator?: deploymentAcceleratorOption;
    userIsInEnterpriseTier?: boolean;
}) => {
    const { autoSuspendSecs, minReplicas, maxReplicas, selectedAccelerator, userIsInEnterpriseTier } = props;
    return <>
        <span style={{ fontSize: `${12 / 14}rem`, marginRight: `1rem` }}>
            {formatCooldownTime(Number(autoSuspendSecs), Number(minReplicas), Number(maxReplicas))}
        </span>
        {selectedAccelerator && !userIsInEnterpriseTier && (
        <span style={{ fontSize: `1rem`, marginRight: `1rem` }}>
                Cost: <b>{centsToDollars(selectedAccelerator?.compute?.cost?.centsPerHour)}/hr</b>
            </span>
        )}
    </>;
};

type DeploymentStep1Props = {
    userIsInFreeTier: boolean;
    userCanCreateMoreDeployments: boolean;
    isEditMode: boolean;
    deploymentName: string;
    setDeploymentName: React.Dispatch<React.SetStateAction<string>>;
    baseModel: string;
    setBaseModel: React.Dispatch<React.SetStateAction<string>>;
    accelerator: string;
    setAccelerator: React.Dispatch<React.SetStateAction<string>>;
    quantization: string;
    setQuantization: React.Dispatch<React.SetStateAction<string>>;
    minReplicas: string;
    setMinReplicas: React.Dispatch<React.SetStateAction<string>>;
    maxReplicas: string;
    setMaxReplicas: React.Dispatch<React.SetStateAction<string>>;
    targetPendingRequests: string;
    setTargetPendingRequests: React.Dispatch<React.SetStateAction<string>>;
    autoSuspendSecs: string;
    setAutoSuspendSecs: React.Dispatch<React.SetStateAction<string>>;
    baseModelSelectorOptions: DropdownItemProps[];
    acceleratorSelectorOptions: DropdownItemProps[];
    quantizationSelectorOptions: DropdownItemProps[];
    setStep: React.Dispatch<React.SetStateAction<number>>;
    resetModalState: () => void;
    ScalingMessage: JSX.Element;
};

const DeploymentStep1 = (props: DeploymentStep1Props) => {
    const {
        userIsInFreeTier,
        userCanCreateMoreDeployments,
        isEditMode,
        deploymentName,
        setDeploymentName,
        baseModel,
        setBaseModel,
        accelerator,
        setAccelerator,
        quantization,
        setQuantization,
        minReplicas,
        setMinReplicas,
        maxReplicas,
        setMaxReplicas,
        targetPendingRequests,
        setTargetPendingRequests,
        autoSuspendSecs,
        setAutoSuspendSecs,
        baseModelSelectorOptions,
        acceleratorSelectorOptions,
        quantizationSelectorOptions,
        setStep,
        resetModalState,
        ScalingMessage,
    } = props;
    return <>
        <Modal.Content>
            <span>
                Spin up a private deployment of a pretrained model which is billed at $/gpu-hour rates{" "}
                {
                    // eslint-disable-next-line react/jsx-no-target-blank
                    <a href="https://predibase.com/pricing" target="_blank" rel="noopener">
                        (See pricing)
                    </a>
                }
            </span>
            <Divider hidden />

            {userIsInFreeTier && (
                <Message warning color={"yellow"} className={"disabled-warning"}>
                    <p className={"message-text"}>
                        <Icon name={"warning sign"} />
                        Private serverless deployments are disabled until a credit card is added. Please go to{" "}
                        <b>{"Settings > Billing"}</b> to add your credit card to create a private serverless
                        deployment.
                    </p>
                </Message>
            )}

            {!userCanCreateMoreDeployments && (
                <Message warning color={"yellow"} className={"disabled-warning"}>
                    <p className={"message-text"}>
                        <Icon name={"warning sign"} />
                        You are limited to one private serverless deployments in the Developer Tier. Please contact{" "}
                        {
                            // eslint-disable-next-line react/jsx-no-target-blank
                            <a href="mailto:sales@predibase.com" target="_blank" rel="noopener">
                                sales@predibase.com
                            </a>
                        }{" "}
                        if you'd like to upgrade to the Enterprise Tier.
                    </p>
                </Message>
            )}
            <Grid>
                <Grid.Row style={{ paddingBottom: 0, paddingTop: `${8 / 14}rem` }}>
                    <Grid.Column mobile={16} tablet={8} computer={8}>
                        <StringInputField
                            name="deploymentName"
                            placeholder="Name"
                            value={deploymentName}
                            setValue={setDeploymentName}
                            header="Deployment name"
                            description="Choose an identifier for your deployment (ex. “my-llama-3-8b”)"
                            fullWidth
                            disabled={isEditMode}
                        />
                    </Grid.Column>
                    <Grid.Column mobile={16} tablet={8} computer={8}>
                        <DropdownField
                            name="baseModel"
                            placeholder="Model"
                            description="Choose the LLM to deploy"
                            value={baseModel}
                            setValue={setBaseModel}
                            header="Base model"
                            options={baseModelSelectorOptions}
                            // allowAdditions
                            fullWidth
                            disabled={isEditMode}
                        />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row style={{ paddingBottom: 0, paddingTop: 0 }}>
                    <Grid.Column mobile={16} tablet={16} computer={16}>
                        <Divider style={{ marginTop: `-${8 / 14}rem`, marginBottom: `${20 / 14}rem` }} />
                    </Grid.Column>
                </Grid.Row>
                {baseModel !== "" && (
                    <>
                        <Grid.Row style={{ paddingBottom: 0, paddingTop: 0 }}>
                            <Grid.Column mobile={16} tablet={8} computer={8}>
                                <DropdownField
                                    // clearable
                                    name="accelerator"
                                    header="Accelerator (GPU)"
                                    placeholder="Accelerator"
                                    description="Choose the hardware to deploy your model on"
                                    value={accelerator}
                                    setValue={setAccelerator}
                                    options={acceleratorSelectorOptions}
                                    fullWidth
                                    disabled={isEditMode}
                                />
                            </Grid.Column>
                            <Grid.Column mobile={16} tablet={8} computer={8}>
                                <DropdownField
                                    // clearable
                                    name="quantization"
                                    header="Quantization"
                                    placeholder="None"
                                    description="Quantization reduces model size, allowing for larger context windows."
                                    value={quantization}
                                    setValue={setQuantization}
                                    options={quantizationSelectorOptions}
                                    fullWidth
                                    disabled={isEditMode}
                                />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row style={{ paddingBottom: 0, paddingTop: 0 }}>
                            <Grid.Column mobile={16} tablet={8} computer={8}>
                                <div style={{ flex: "1" }}>
                                    <IntegerInputField
                                        name="minReplicas"
                                        placeholder="Min Replicas"
                                        header="Min replicas"
                                        description="The minimum number of replicas your deployment will scale down to"
                                        value={minReplicas}
                                        setValue={setMinReplicas}
                                        fullWidth
                                    />
                                    <p
                                        style={{
                                            color: SEMANTIC_GREY,
                                            fontSize: "0.9em",
                                            paddingBottom: `${32 / 14}rem`,
                                            marginTop: "-1rem",
                                        }}
                                    >
                                        Note: If min and max replicas are equal, the deployment will never scale any
                                        replicas down, effectively ignoring the next two fields.
                                    </p>
                                </div>
                            </Grid.Column>
                            <Grid.Column mobile={16} tablet={8} computer={8}>
                                <div style={{ flex: "1" }}>
                                    <IntegerInputField
                                        name="maxReplicas"
                                        placeholder="Max Replicas"
                                        header="Max replicas"
                                        description="The maximum number of replicas your deployment will scale up to"
                                        value={maxReplicas}
                                        setValue={setMaxReplicas}
                                        fullWidth
                                    />
                                </div>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row style={{ paddingTop: 0 }}>
                            <Grid.Column mobile={16} tablet={8} computer={8}>
                                <IntegerInputField
                                    name="targetPendingRequests"
                                    placeholder="Scale Up Threshold"
                                    header="Scale up threshold (requests)"
                                    description="Defines the pending (or in-progress) requests each active replica must have before scaling up past 1 replica."
                                    value={targetPendingRequests}
                                    setValue={setTargetPendingRequests}
                                    fullWidth
                                />
                                <p style={{ color: SEMANTIC_GREY, fontSize: "0.9em", marginTop: "-1rem" }}>
                                    Note: If all replicas have fewer requests than the scale up threshold, extra
                                    replicas scale down after the cooldown time.
                                </p>
                            </Grid.Column>
                            <Grid.Column mobile={16} tablet={8} computer={8}>
                                <IntegerInputField
                                    name="autoSuspend"
                                    placeholder="Cooldown"
                                    header="Cooldown time (seconds)"
                                    description="The duration after which your deployment will automatically scale down replicas if it no longer needs them"
                                    value={autoSuspendSecs}
                                    setValue={setAutoSuspendSecs}
                                    fullWidth
                                />
                            </Grid.Column>
                        </Grid.Row>
                    </>
                )}
            </Grid>
        </Modal.Content>
        <Modal.Actions>
            {ScalingMessage}
            <Button
                size={"small"}
                onClick={() => {
                    resetModalState();
                }}
            >
                Cancel
            </Button>
            <Button
                color={"blue"}
                size={"small"}
                onClick={() => {
                    setStep(2);
                }}
            >
                Next
            </Button>
        </Modal.Actions>
    </>;
};

type DeploymentStep2Props = {
    isEditMode: boolean;
    minReplicas: string;
    preloadedAdapters: string[];
    setPreloadedAdapters: React.Dispatch<React.SetStateAction<string[]>>;
    preloadedAdaptersSelectorOptions: string[];
    requiresReservation: boolean;
    useGuaranteedCapacity: boolean;
    setUseGuaranteedCapacity: React.Dispatch<React.SetStateAction<boolean>>;
    createMutationPending: boolean;
    resetMutationPending: boolean;
    canDeploy: boolean;
    createOrUpdateDeployment: () => void;
    errorMessage: string | null;
    reservations: computeReservation[];
    setStep: React.Dispatch<React.SetStateAction<number>>;
    resetModalState: () => void;
};

const DeploymentStep2 = (props: DeploymentStep2Props) => {
    const {
        isEditMode,
        minReplicas,
        preloadedAdapters,
        setPreloadedAdapters,
        preloadedAdaptersSelectorOptions,
        requiresReservation,
        useGuaranteedCapacity,
        setUseGuaranteedCapacity,
        createMutationPending,
        resetMutationPending,
        canDeploy,
        createOrUpdateDeployment,
        errorMessage,
        reservations,
        setStep,
        resetModalState
    } = props;
    const [searchPreloadedAdapters, setSearchPreloadedAdapters] = useState<string>("");
    return <>
        <Modal.Content>
            <h2 style={{ fontSize: "1rem", fontStyle: "normal", lineHeight: "1.57143rem", marginBottom: "0.14rem" }}>Pre-loaded Adapters</h2>
            <p style={{ color: SEMANTIC_GREY, fontSize: "0.9em", marginBottom: `1.14rem` }}>Preloaded adapters speed up cold start times when you have multiple replicas or scale up from 0.</p>
            {(isEditMode && Number(minReplicas) < 2) && <Message info>
                <Message.Header>Potential Service Interruption</Message.Header>
                <Message.Content>
                    <p>When a deployment is updated, 1 replica of the deployment is spun down before a new one is created. If your deployment has 1 replica or is currently running with only 1 replica, the replica will be shutdown to complete the update.</p>
                    <p>To prevent downtime, set the minimum number of replicas to 2. This will insure at least one replica is running during the update.</p>
                </Message.Content>
            </Message>}
            <Grid>
                <Grid.Row style={{ paddingBottom: 0, paddingTop: `${8 / 14}rem` }}>
                    <Grid.Column mobile={16} tablet={8} computer={8}>
                        <Input
                            placeholder="Filter"
                            value={searchPreloadedAdapters}
                            onChange={(e) => setSearchPreloadedAdapters(e.target.value)}
                            fluid
                            icon={<Icon name="search" />}
                        />
                        <ul style={{
                            listStyle: "none",
                            height: "20vh",
                            overflowY: "auto",
                            backgroundColor: PREDIBASE_LIGHT_GRAY,
                            padding: "0.25rem",
                            border: `1px solid #DEDEDF`,
                        }}>
                            {preloadedAdaptersSelectorOptions.filter((adapter) => adapter.toLowerCase().includes(searchPreloadedAdapters.toLowerCase())).map((adapter) => {
                                const isSelected = preloadedAdapters.includes(adapter);
                                return (
                                    <li key={adapter}>
                                        <button
                                            onClick={() => !isSelected && setPreloadedAdapters((currentList) => [...currentList, adapter])}
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "space-between",
                                                width: "100%",
                                                background: "none",
                                                cursor: "pointer",
                                                border: `1px solid ${isSelected ? "#2185D0" : "#DEDEDF"}`,
                                                borderRadius: "0.42857rem",
                                                padding: "0.57rem 0.64rem 0.57rem 1.14rem",
                                                marginBottom: "0.125rem",
                                                color: "#2185D0",
                                                backgroundColor: isSelected ? "#DFF0FF" : "white",
                                                fontFamily: "Lato",
                                            }}
                                        >
                                            {adapter} {isSelected ? <Icon name="check circle outline" color="blue"/> : <Icon name="check circle" color="grey" disabled={true}/>}
                                        </button>
                                    </li>
                                );
                            })}
                        </ul>
                    </Grid.Column>
                    <Grid.Column mobile={16} tablet={8} computer={8}>
                        <h3 style={{fontSize: "1rem", fontStyle: "normal", lineHeight: "1.57143rem"}}>Selected adapters to pre-load <span style={{ color: SEMANTIC_GREY }}>({preloadedAdapters.length} selected)</span></h3>
                        <Divider />
                        <ul
                            style={{
                                listStyle: "none",
                                padding: 0,
                                height: "20vh",
                                overflowY: "auto",
                                display: "flex",
                                flexDirection: "column",
                                gap: "0.125rem",
                            }}
                        >
                            {preloadedAdapters.map((adapter) => {
                                return (
                                    <li key={adapter}>
                                        <button
                                            onClick={() => setPreloadedAdapters((currentList) => currentList.filter((a) => a !== adapter))}
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "space-between",
                                                height: "2.35714rem",
                                                width: "100%",
                                                background: "none",
                                                cursor: "pointer",
                                                border: "1px solid #2185D0",
                                                borderRadius: "0.42857rem",
                                                padding: "0.57rem 0.64rem 0.57rem 1.14rem",
                                                color: "#2185D0",
                                                backgroundColor: "#DFF0FF",
                                                fontFamily: "Lato",
                                            }}
                                        >
                                            {adapter} {<Icon name="remove circle" color="blue"/>}
                                        </button>
                                    </li>
                                );
                            })}
                        </ul>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column mobile={16} tablet={16} computer={16}>
                        <div style={{ width: "100%" }}>
                            {/* TODO: Add a tooltip that says "The accelerator requires a reservation." */}
                            <Popup
                                content="The accelerator requires a reservation."
                                trigger={
                                    <div style={{ display: "flex", alignItems: "center", marginBottom: "0.29rem"}} >
                                        <Checkbox
                                            checked={useGuaranteedCapacity}
                                            onChange={(event: SyntheticEvent, data: CheckboxProps) => {
                                                setUseGuaranteedCapacity(data.checked ?? false);
                                            }}
                                            disabled={requiresReservation}
                                        />
                                        <Header as={"h2"} size={"small"} style={{ margin: `0 0 0 0.86rem` }}>
                                        Use reserved capacity
                                        </Header>
                                    </div>
                                }
                                disabled={!requiresReservation}
                            />
                            <p style={{ color: SEMANTIC_GREY, fontSize: "0.9em", marginBottom: `${5 / 14}rem`, marginLeft: "2.0479rem" }}>
                                Whether the deployment should draw from the reserved capacity you've pre-purchased for the given accelerator.
                                {" "}
                                <Popup
                                    content={<>
                                        <strong>Active reservations:</strong>
                                        <ul>
                                            {reservations.map((reservation) => (
                                                <li>{formatAcceleratorID(reservation.accelerator)} - {reservation.usedCapacity} of {reservation.reservedCapacity} replica{reservation.reservedCapacity > 1 ? "s" : ""} used</li>
                                            ))}
                                        </ul>
                                    </>}
                                    trigger={<span style={{color: SEMANTIC_BLUE}}>View your reservations.</span>}
                                    enabled={useGuaranteedCapacity}
                                    wide
                                />
                            </p>
                        </div>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            {errorMessage && <Message error>{errorMessage}</Message>}
        </Modal.Content>
        <Modal.Actions>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Button
                    size={"small"}
                    onClick={() => {
                        setStep(1);
                    }}
                >
                    Back
                </Button>
                <div style={{ display: "flex" }}>
                    <Button
                        size={"small"}
                        onClick={() => {
                            resetModalState();
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        icon
                        color={"green"}
                        labelPosition={"right"}
                        size={"small"}
                        loading={createMutationPending || resetMutationPending}
                        disabled={!canDeploy}
                        onClick={() => {
                            createOrUpdateDeployment();
                        }}
                    >
                        {isEditMode ? "Update" : "Deploy"}
                        <Icon inverted name="rocket" color={"green"} />
                    </Button>
                </div>
            </div>
        </Modal.Actions>
    </>;
};

const CreateUpdateDeploymentModal = (props: {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    deployments?: deployment[];
    deployment?: deployment;
}) => {
    const { deployment, deployments } = props;
    // React Query is sending an empty object when the API is still loading, so we need to check for that:
    const isEditMode = !!deployment && Object.getOwnPropertyNames(deployment).length > 0;

    // Recoil state:
    const [userContext] = useRecoilState(USER_STATE);
    // Derived user state:
    let userTier: tier | undefined;
    if (userContext) {
        const isKratosContext = isKratosUserContext(userContext);
        userTier = isKratosContext ? userContext.tenant.subscription.tier : userContext?.tenant.tier;
    }
    const userIsInFreeTier = userTier === tier.FREE;
    const userIsInDevTier = userTier === tier.PREMIUM;
    const userIsInEnterpriseTier = userTier && [tier.ENTERPRISE_SAAS, tier.ENTERPRISE_VPC].includes(userTier);
    const numDedicatedDeployments = deployments?.length;

    // Auth0 state:
    const auth0TokenOptions = useAuth0TokenOptions();

    // Local state:
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [step, setStep] = useState<number>(1);

    // Defaults
    const acceleratorDefault = "";
    const quantizationDefault = "";
    const autoSuspendSecsDefault = "3600";
    const minReplicasDefault = "0";
    const maxReplicasDefault = "1";
    const targetPendingRequestsDefault = "1";

    // Required variables:
    const [deploymentName, setDeploymentName] = useState<string>("");
    const [baseModel, setBaseModel] = useState<string>("");
    const [accelerator, setAccelerator] = useState<string>(acceleratorDefault);
    const [quantization, setQuantization] = useState<string>(quantizationDefault);
    const [autoSuspendSecs, setAutoSuspendSecs] = useState<string>(autoSuspendSecsDefault);
    const [minReplicas, setMinReplicas] = useState<string>(minReplicasDefault);
    const [maxReplicas, setMaxReplicas] = useState<string>(maxReplicasDefault);
    const [targetPendingRequests, setTargetPendingRequests] = useState<string>(targetPendingRequestsDefault);
    const [preloadedAdapters, setPreloadedAdapters] = useState<string[]>(deployment?.config?.preloadedAdapters ?? []);
    const [useGuaranteedCapacity, setUseGuaranteedCapacity] = useState<boolean>(false);

    // Query state:
    const queryClient = useQueryClient();
    const { data: baseModels } = useBaseModelsQuery({
        refetchOnWindowFocus: false,
    });

    const { data: availableAdapters } = useAdaptersListQuery({
        enabled: !!baseModel,
        refetchOnWindowFocus: false,
    });

    // TODO NOAH get the accelerator data from the normal place? Add it in the go code to return "reservation required"
    const { data: reservations } = useReservationsQuery({
        refetchOnWindowFocus: false,
    });

    /**
     * Mutations
     */
    const {
        mutate: mutateCreateDeployment,
        reset: resetCreateDeploymentMutation,
        isPending: createMutationPending,
    } = useMutation({
        mutationFn: (config: createDeploymentRequest) => createDeployment(config, auth0TokenOptions),
        onSuccess: () => {
            props.setOpen(false);
            resetModalState();
            queryClient.invalidateQueries({ queryKey: GET_DEPLOYMENTS_QUERY_KEY });
        },
        onError: (error) => {
            setErrorMessage(getErrorMessage(error));
        },
    });

    const {
        mutate: mutateUpdateDeployment,
        reset: resetUpdateDeploymentMutation,
        isPending: resetMutationPending,
    } = useMutation({
        mutationFn: (request: updateDeploymentRequest) =>
            updateDeployment(deployment?.uuid ?? "", request, auth0TokenOptions),
        onSuccess: () => {
            props.setOpen(false);
            resetModalState();
            queryClient.invalidateQueries({ queryKey: GET_DEPLOYMENTS_QUERY_KEY });
        },
        onError: (error) => {
            setErrorMessage(typeof error === "string" ? error : getErrorMessage(error as any));
        },
    });

    /**
     * Static Transformed Data
     */
    // Variables that should only be generated once (after new data is fetched):
    const { baseModelSelectorOptions, baseModelLookup } = useMemo(() => {
        const [baseModelSelectorOptions, baseModelLookup] = generateBaseModelSelectorOptions(baseModels, userTier);
        return { baseModelSelectorOptions, baseModelLookup };
    }, [baseModels, userTier]);

    /**
     * Reactive Data (based on user selections)
     */
    const preloadedAdaptersSelectorOptions = useMemo(() => {
        return availableAdapters
        ?.filter((adapter) => deploymentModelSupportsAdapterModel(baseModel, adapter.baseModel))
        ?.map((adapter) => `${adapter.repo}/${adapter.versionTag}`) ?? [];
    }, [availableAdapters, baseModel]);

    // Update selector options based on the base model:
    const { acceleratorSelectorOptions, acceleratorLookup, acceleratorsQuantizationMap } = useMemo(() => {
        const baseModelObj = baseModelLookup[baseModel] as baseModel | undefined;
        // TODO: Probably need to rewrite this
        let accelerators: deploymentAcceleratorOption[] = [];
        const acceleratorsMap = new Map<string, deploymentAcceleratorOption>();
        let acceleratorsQuantizationMap: Record<string, deploymentQuantization[]> = {};

        if (baseModelObj?.accelerators?.serving) {
            Object.entries(baseModelObj.accelerators.serving).forEach(([quantizationType, availableAccelerators]) => {
                // Add accelerators to set to remove duplicates:
                availableAccelerators?.forEach((accelerator) => {
                    acceleratorsMap.set(accelerator.id, accelerator);
                });

                // Append to the quantization map:
                for (const accelerator of availableAccelerators) {
                    if (!userTier || !accelerator.availability.tiers.includes(userTier)) {
                        continue;
                    }

                    if (acceleratorsQuantizationMap[accelerator.id]) {
                        acceleratorsQuantizationMap[accelerator.id].push(quantizationType as deploymentQuantization);
                        continue;
                    }
                    acceleratorsQuantizationMap[accelerator.id] = [quantizationType as deploymentQuantization];
                }
            });
        }

        acceleratorsMap.forEach((accelerator) => accelerators.push(accelerator));
        const [acceleratorSelectorOptions, acceleratorLookup] = generateAcceleratorSelectorOptions(
            accelerators,
            userTier,
            reservations,
        );
        return { acceleratorSelectorOptions, acceleratorLookup, acceleratorsQuantizationMap };
    }, [baseModel, baseModelLookup, userTier, reservations]);

    // Update quantization options based on the accelerator:
    const quantizationSelectorOptions = useMemo(() => {
        let quantizationSelectorOptions: DropdownItemProps[] = [];
        if (!accelerator || !acceleratorsQuantizationMap[accelerator]) {
            return quantizationSelectorOptions;
        }

        acceleratorsQuantizationMap[accelerator]?.sort(sortQuantizationTypes)?.forEach((quantization) =>
            quantizationSelectorOptions.push({
                key: quantization,
                text: upperFirst(quantization),
                value: quantization,
            }),
        );

        return quantizationSelectorOptions;
    }, [accelerator, acceleratorsQuantizationMap]);

    // Auto-select the first non-disabled accelerator option after the base model changes:
    useEffect(() => {
        if (!Array.isArray(acceleratorSelectorOptions) || acceleratorSelectorOptions.length === 0) {
            return;
        }

        const newAccelerator = acceleratorSelectorOptions.filter((opt) => !Boolean(opt.disabled))[0]
            ?.value as acceleratorId;
        setAccelerator(newAccelerator);
    }, [baseModel, acceleratorSelectorOptions]);

    // Auto-select the first quantization option after the accelerator changes:
    useEffect(() => {
        if (
            !Array.isArray(acceleratorsQuantizationMap[accelerator]) ||
            acceleratorsQuantizationMap[accelerator].length === 0
        ) {
            return;
        }
        setQuantization(acceleratorsQuantizationMap[accelerator][0]);
        setUseGuaranteedCapacity(acceleratorLookup[accelerator as acceleratorId]?.reservationRequired === true);
    }, [accelerator, acceleratorLookup, acceleratorsQuantizationMap]);

    // Convert local state to actual base model and accelerator objects:
    let selectedBaseModel = baseModelLookup[baseModel] as baseModel | undefined;
    // ! WARNING: "undefined" here is actually the "auto" value -- see dropdown-utils.tsx:
    let selectedAccelerator = acceleratorLookup[accelerator as acceleratorId] as
        | deploymentAcceleratorOption
        | undefined;

    /**
     * Edit Mode
     */
    // Set the initial values based on the deployment:
    useEffect(() => {
        if (deployment === undefined || Object.getOwnPropertyNames(deployment).length === 0) {
            return;
        }

        setDeploymentName(deployment?.name ?? "");
        setBaseModel(deployment?.model?.name ?? "");
        setAccelerator(deployment?.accelerator?.id ?? "");
        setQuantization(deployment?.quantization ?? quantizationDefault);
        setAutoSuspendSecs(String(deployment?.config?.cooldownTime ?? autoSuspendSecsDefault));
        setMinReplicas(String(deployment?.config?.minReplicas ?? minReplicasDefault));
        setMaxReplicas(String(deployment?.config?.maxReplicas ?? maxReplicasDefault));
        setTargetPendingRequests(String(deployment?.config?.scaleUpRequestThreshold ?? targetPendingRequestsDefault));
    }, [deployment, props.open]);

    // For custom models, we need to add the current values so the user can successfully edit.
    if (isEditMode && selectedBaseModel === undefined) {
        // Add the current values to the base model list:
        selectedBaseModel = deployment.model;

        const baseModelName = selectedBaseModel?.name ?? "";
        baseModelSelectorOptions.push({
            key: baseModelName,
            text: baseModelName,
            value: baseModelName,
        });

        // Add the current values to the accelerator list:
        selectedAccelerator = {
            ...deployment.accelerator,
            maxBatchPrefillTokens: deployment?.model?.maxBatchPrefillTokens ?? 0,
            maxTotalTokens: deployment?.model?.maxTotalTokens ?? 0,
            maxInputLength: deployment?.model?.maxInputLength ?? 0,
        };
        acceleratorSelectorOptions.push({
            key: selectedAccelerator?.id,
            text: selectedAccelerator?.name,
            value: selectedAccelerator?.id,
        });

        // Add the current values to the quantization list:
        const deploymentQuant = deployment?.quantization;
        if (
            deploymentQuant &&
            quantizationSelectorOptions.indexOf(
                (quantOption: DropdownItemProps) => quantOption.value === deploymentQuant,
            ) === 0
        ) {
            quantizationSelectorOptions.push({
                key: deployment?.quantization,
                text: upperFirst(deployment?.quantization),
                value: deployment?.quantization,
            });
        }
    }

    /**
     * Derived State for warnings, blocking, messages
     */
    const userCanCreateMoreDeployments =
        numDedicatedDeployments !== undefined && userIsInDevTier ? numDedicatedDeployments < 1 : true;
    const userChoseValidBaseModel =
        selectedBaseModel && userIsInDevTier ? !isEnterpriseTierModel(selectedBaseModel) : true;
    const userChoseValidAccelerator = selectedAccelerator
        ? selectedAccelerator?.availability?.tiers?.includes(userTier as unknown as tier) // TODO: Need to update SubscriptionType to generated version
        : false; // Users must choose an accelerator
    const canDeploy =
        deploymentName &&
        selectedBaseModel &&
        (userIsInDevTier || userIsInEnterpriseTier) &&
        userCanCreateMoreDeployments &&
        userChoseValidBaseModel &&
        minReplicas &&
        maxReplicas &&
        (targetPendingRequests || minReplicas === maxReplicas) &&
        (autoSuspendSecs || minReplicas === maxReplicas) &&
        userChoseValidAccelerator;

    const resetModalState = () => {
        setDeploymentName("");
        setBaseModel("");
        setAccelerator(acceleratorDefault);
        setQuantization(quantizationDefault);
        setAutoSuspendSecs(autoSuspendSecsDefault);
        setMinReplicas(minReplicasDefault);
        setMaxReplicas(maxReplicasDefault);
        setTargetPendingRequests(targetPendingRequestsDefault);
        setPreloadedAdapters([]);
        setUseGuaranteedCapacity(false);
        setStep(1);
        props.setOpen(false);
        resetCreateDeploymentMutation();
        resetUpdateDeploymentMutation();
    };

    const createOrUpdateDeployment = () => {
        if (isEditMode) {
            resetUpdateDeploymentMutation();
            mutateUpdateDeployment({
                // description: "", TODO: Implement this?
                config: {
                    minReplicas: minReplicas ? Number(minReplicas) : undefined,
                    maxReplicas: maxReplicas ? Number(maxReplicas) : undefined,
                    cooldownTime: autoSuspendSecs ? Number(autoSuspendSecs) : undefined,
                    scaleUpRequestThreshold: targetPendingRequests
                        ? Number(targetPendingRequests)
                        : undefined,
                    // hfToken: huggingfaceToken, TODO: Implement this?
                    customArgs: deployment?.config?.customArgs,
                    loraxImageTag: deployment?.config?.loraxImageTag,
                    preloadedAdapters: preloadedAdapters,
                },
            });

            return;
        }

        resetCreateDeploymentMutation();
        mutateCreateDeployment({
            name: deploymentName,
            // description: "", TODO: Implement this?
            config: {
                baseModel: selectedBaseModel!.name,
                usesGuaranteedCapacity: useGuaranteedCapacity,
                accelerator: selectedAccelerator?.id,
                quantization: quantization
                    ? (quantization as unknown as deploymentQuantization)
                    : undefined,
                minReplicas: minReplicas ? Number(minReplicas) : undefined,
                maxReplicas: maxReplicas ? Number(maxReplicas) : undefined,
                cooldownTime: autoSuspendSecs ? Number(autoSuspendSecs) : undefined,
                scaleUpRequestThreshold: targetPendingRequests
                    ? Number(targetPendingRequests)
                    : undefined,
                // hfToken: huggingfaceToken, TODO: Implement this?
                preloadedAdapters: preloadedAdapters,
                speculator: "auto"
            },
        });
    };

    return (
        <Modal
            name="createUpdateDeploymentModal"
            onOpen={() => {
                props.setOpen(true);
            }}
            onClose={() => {
                props.setOpen(false);
                resetCreateDeploymentMutation();
                resetUpdateDeploymentMutation();
                resetModalState();
            }}
            open={props.open}
        >
            <Modal.Header>{isEditMode ? "Update" : "New"} Private Serverless Deployment</Modal.Header>
            {step === 1 && <DeploymentStep1
                userIsInFreeTier={userIsInFreeTier}
                userCanCreateMoreDeployments={userCanCreateMoreDeployments}
                isEditMode={isEditMode}
                deploymentName={deploymentName}
                setDeploymentName={setDeploymentName}
                baseModel={baseModel}
                setBaseModel={setBaseModel}
                accelerator={accelerator}
                setAccelerator={setAccelerator}
                quantization={quantization}
                setQuantization={setQuantization}
                minReplicas={minReplicas}
                setMinReplicas={setMinReplicas}
                maxReplicas={maxReplicas}
                setMaxReplicas={setMaxReplicas}
                targetPendingRequests={targetPendingRequests}
                setTargetPendingRequests={setTargetPendingRequests}
                autoSuspendSecs={autoSuspendSecs}
                setAutoSuspendSecs={setAutoSuspendSecs}
                baseModelSelectorOptions={baseModelSelectorOptions}
                acceleratorSelectorOptions={acceleratorSelectorOptions}
                quantizationSelectorOptions={quantizationSelectorOptions}
                setStep={setStep}
                resetModalState={resetModalState}
                ScalingMessage={<ScalingMessage
                    autoSuspendSecs={autoSuspendSecs}
                    minReplicas={minReplicas}
                    maxReplicas={maxReplicas}
                    selectedAccelerator={selectedAccelerator}
                    userIsInEnterpriseTier={userIsInEnterpriseTier}
                />}
            />}
            {step === 2 && <DeploymentStep2
                isEditMode={isEditMode}
                minReplicas={minReplicas}
                preloadedAdapters={preloadedAdapters}
                setPreloadedAdapters={setPreloadedAdapters}
                preloadedAdaptersSelectorOptions={preloadedAdaptersSelectorOptions}
                requiresReservation={selectedAccelerator?.reservationRequired === true}
                useGuaranteedCapacity={useGuaranteedCapacity}
                setUseGuaranteedCapacity={setUseGuaranteedCapacity}
                createMutationPending={createMutationPending}
                resetMutationPending={resetMutationPending}
                canDeploy={Boolean(canDeploy)}
                createOrUpdateDeployment={createOrUpdateDeployment}
                errorMessage={errorMessage}
                reservations={reservations ?? []}
                setStep={setStep}
                resetModalState={resetModalState}
            />}
        </Modal>
    );
};

export default CreateUpdateDeploymentModal;
