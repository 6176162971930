import { ValidateFunction } from "ajv";
import _ from "lodash";
import { useReducer } from "react";
import { createContainer } from "react-tracked";
import { compileJSONSchema, validateConfigAgainstSchema } from "../../../utils/ajv";
import { FeatureFlags } from "../../../utils/feature-flags";
import { AdapterConfig, AdapterConfigSchema, AdapterConfigSchemaType } from "./schema";
import { baseModel } from "../../../api_generated";
import { getTargetModulesDefaultValue } from "./utils";
import { getSelectedLLM } from "./utils";

type AdapterConfigState = {
    config?: Partial<AdapterConfig>; // TODO: Should this be optional if it is initialized as an empty object?
    configKey: number;
    validator?: ValidateFunction;
    schema: AdapterConfigSchemaType;
    dirtyDefault: boolean;
    invalidFields: InvalidFields;
    baseModels: baseModel[];
    featureFlags: FeatureFlags;
};

export type AdapterConfigStateAction =
    | {
          type: "INIT";
          config?: Partial<AdapterConfig>;
          baseModels: baseModel[];
          featureFlags: FeatureFlags;
      }
    | { type: "USE_SUGGESTED_CONFIG"; suggestedConfig?: any }
    | { type: "UPDATE_CONFIG"; config?: Partial<AdapterConfig>; isDirty?: boolean }
    | { type: "UPDATE_CONFIG_PROPERTY"; field: string; value: string | number | boolean | string[] | null | undefined }
    | { type: "REMOVE_CONFIG_PROPERTY"; field: string }
    | { type: "UPDATE_FEATURE_FLAGS"; featureFlags: FeatureFlags };

export const initialState = {
    config: {} as Partial<AdapterConfig>,
    configKey: 0,
    schema: AdapterConfigSchema,
    validator: undefined,
    dirtyDefault: false,
    invalidFields: {},
    baseModels: [],
    featureFlags: {},
};

export const reducer = (state: AdapterConfigState, action: AdapterConfigStateAction): AdapterConfigState => {
    let config: Partial<AdapterConfig> | undefined;
    let invalidFields = {};

    switch (action.type) {
        case "INIT":
            // Use default LLM config if no config is provided
            config = action.config;
            if (!config) {
                config = {
                    base_model: state.schema?.properties.base_model?.default,
                    adapter: state.schema?.properties.adapter?.default as AdapterConfig["adapter"],
                    task: state.schema?.properties.task?.default as AdapterConfig["task"],
                    rank: state.schema?.properties.rank?.default as AdapterConfig["rank"],
                    epochs: state.schema?.properties.epochs?.default,
                    learning_rate: state.schema?.properties.learning_rate?.default,
                };
            }

            const validator = compileJSONSchema(state.schema);
            invalidFields = validateConfigAgainstSchemaWrapper(action.featureFlags, validator, config);

            return {
                ...state,
                validator,
                config,
                baseModels: action.baseModels,
                dirtyDefault: false,
                invalidFields,
                featureFlags: action.featureFlags,
            };
        case "UPDATE_CONFIG":
            config = action.config;
            invalidFields = validateConfigAgainstSchemaWrapper(state.featureFlags, state.validator, config);

            return {
                ...state,
                config,
                dirtyDefault: action.isDirty ?? true,
                invalidFields,
            };
        case "UPDATE_CONFIG_PROPERTY":
            config = _.cloneDeep(state.config ? state.config : {}) as any;
            if (action.value === "" || action.value === undefined) {
                _.unset(config, action.field);
                removeEmptyConfigProperties(action.field, config);
            } else if (config !== undefined) {
                _.set<Partial<AdapterConfig>>(config, action.field, action.value);
            }

            if (action.field === "base_model") {
                const newSelectedBaseModel = getSelectedLLM(action.value as string, state.baseModels);
                _.set<Partial<AdapterConfig>>(config ?? {}, "target_modules", getTargetModulesDefaultValue(newSelectedBaseModel));
            }

            invalidFields = validateConfigAgainstSchemaWrapper(state.featureFlags, state.validator, config);

            return {
                ...state,
                config,
                dirtyDefault: true,
                invalidFields,
            };
        case "REMOVE_CONFIG_PROPERTY":
            config = _.cloneDeep(state.config) as any;
            _.unset(config, action.field);
            removeEmptyConfigProperties(action.field, config);

            invalidFields = validateConfigAgainstSchemaWrapper(state.featureFlags, state.validator, config);

            return {
                ...state,
                config,
                dirtyDefault: true,
                invalidFields,
            };
        case "UPDATE_FEATURE_FLAGS":
            return {
                ...state,
                featureFlags: action.featureFlags,
            };
        default:
            return state;
    }
};

/**
 * A function to recursively go through the updated config property and remove any empty leafs.
 * @param field the field being updated.
 * @param config the config object to validate.
 * @returns a pruned config object
 */
export const removeEmptyConfigProperties = (field: string, config?: Partial<AdapterConfig>) => {
    const configProperties = field.split(".");

    while (configProperties.length) {
        let configProperty = configProperties.pop();
        // Make sure we handle arrays, such as input_features and hyperopt search parameters.
        if (configProperty?.includes("[")) {
            configProperties.push(configProperty.substring(0, configProperty.indexOf("[")));
            configProperty = configProperty.substring(configProperty.indexOf("[") + 1, configProperty.length - 1);
        }

        if (!_.isEmpty(_.get(config, configProperties.join(".")))) {
            break;
        }

        _.unset(config, configProperties.join("."));
    }

    return config;
};

const validateConfigAgainstSchemaWrapper = (
    featureFlags: FeatureFlags,
    validator?: ValidateFunction,
    config?: CreateModelConfig | Partial<AdapterConfig>,
) => {
    const modelErrors = featureFlags["Model Editor - Disable Validation"]
        ? {}
        : validateConfigAgainstSchema(validator, config);

    return modelErrors;
};

const useValue = () => useReducer(reducer, initialState);

const { Provider, useTrackedState, useUpdate } = createContainer(useValue);

export { Provider as ConfigProvider, useTrackedState as useConfigState, useUpdate as useDispatch };
